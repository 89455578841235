<template>
  <div class="loop-pos-dropoff">
    <div class="loop-pos-dropoff__content">
      <div
        class="loop-pos-dropoff__qr-code"
      >
        <img
          class="loop-pos-dropoff__qr-code-image"
          :src="returnMethod.qrCodeUrl"
        />
        <base-text
          v-if="showRmaCode"
          class="loop-pos-dropoff__rmacode"
        >
          {{ formattedRmaCode }}
        </base-text>
      </div>
      <div class="loop-pos-dropoff__location">
        <base-text class="loop-pos-dropoff__distance">
          <render-content :data="distance">
            {{ $content.moduleStoreDropOff.closestDropOffCopy }}
          </render-content>
        </base-text>
        <address-display
          :address="displayAddress"
          align="left"
          leading="none"
        />
        <div class="dropoff-button-container">
          <base-button
            v-track="'drop off get directions clicked'"
            type="primary"
            class="loop-pos-dropoff__button"
            target="_blank"
            :to="googleMapsLink"
          >
            <span class="loop-pos-dropoff__button-label">
              <base-icon
                class="loop-pos-dropoff__icon"
                name="location"
              />
              {{ $content.moduleStoreDropOff.directionsButton }}
            </span>
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BaseText,
  BaseButton,
  BaseIcon,
} from '@loophq/design-system';

import AddressDisplay from '@/components/AddressDisplay';
import { featureFlags } from '@/js/constants/featureFlags';

export default {
  components: {
    BaseText,
    AddressDisplay,
    BaseButton,
    BaseIcon,
  },
  props: {
    returnMethod: {
      type: Object,
      required: true,
    },
    address: {
      type: Object,
      required: true,
    },
    orderName: {
      type: String,
      required: true
    }
  },
  computed: {
    displayAddress() {
      // eslint-disable-next-line
      const { name, country, ...rest } = this.returnMethod.address;
      return { ...rest, country: '' };
    },
    googleMapsLink() {
      return `https://www.google.com/maps/dir/?api=1&destination=${this.returnMethod?.address?.latitude},${this.returnMethod?.address?.longitude}`;
    },
    distance() {
      return {
        distance: this.returnMethod?.distance?.value.toFixed(2),
        unit: this.returnMethod?.distance?.unit === 'm' ? 'miles' : this.returnMethod?.distance?.unit
      };
    },
    orderWithoutPrefix() {
      return this.orderName.charAt(0) === '#' ? this.orderName.slice(1) : this.orderName;
    },
    showRmaCode() {
      return this.returnMethod.rmaCode && this.$store.getters.hasFeature(featureFlags.ENABLE_SHORTENED_QR_CODE_ID);
    },
    formattedRmaCode() {
      let chunkedArray = this.returnMethod.rmaCode.match(/.{1,4}/g);
      return chunkedArray.reduce(function(pre, next) {
        return pre + ' ' + next;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
$block: '.loop-pos-dropoff';

#{$block} {
  margin-top: var(--spacing-200);

  &__content {
    display: flex;
    align-items: center;
  }

  &__order {
    border-radius: 12px;
    padding: var(--spacing-700) var(--spacing-500);
    max-width: 280px;
    width: 100%;
    background-color: var(--grey-100);
    margin-left: auto;
    margin-right: auto;
  }

  &__order-name {
    font-family: var(--mono-system-font);
    font-weight: 600;
    color: var(--color-gray-900);
  }

  &__qr-code {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__qr-code-image {
    max-width: 180px;
    padding: var(--spacing-200);
    border-radius: 7px;
  }

  &__eyebrow {
    font-size: 1rem;
    line-height: 2;
  }

  &__location {
    flex: 1 1 100%;
    padding: var(--spacing-400);
  }

  &__distance {
    font-weight: bold;
  }

  &__button {
    width: 100%;
    margin-top: var(--spacing-400);
    font-size: 0.9375rem;
  }

  &__button-label {
    display: flex;
    justify-content: center;
    -webkit-font-smoothing: antialiased;
  }

  &__icon {
    width: 24px;
    margin-right: var(--spacing-100);
  }

  &__rmacode {
    @include font-heading4;

    margin-top: var(--spacing-300);
    color: $black;
  }
}

@media screen and (max-width: $break-medium) {
  #{$block} {
    &__content {
      flex-direction: column;
      align-items: initial;
    }

    &__order {
      max-width: 220px;
    }

    &__location {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
</style>
