import {
  getSortedUniqueImageUrls,
  getProductOptionValue,
  getDescriptionText,
  extractPayloadValuesFromProductTags,
  buildString
} from '@/js/helpers/resell/payload';

/**
 * Loads any necessary scripts for the integration.
 * @returns {void}
 */
export const loadPoshmarkSDK = (sourceUri) => {
  if (!sourceUri) {
    console.error('Failed to load Poshmark Resell SDK: source URI not found.');
    return;
  }

  const script = document.createElement('script');
  script.src = sourceUri;
  script.onerror = (e) => {
    console.error('Failed to load Poshmark Resell SDK', e);
  };

  document.head.appendChild(script);
};

/**
 * Generates the SDK payload for the integration for a line item.
 * @param {Object} item - The line item to generate the payload for.
 * @param {Object} config - The configuration for the integration.
 * @returns {Object}
 */
export const generatePoshmarkSDKLineItemPayload = (item, config = {}) => {
  const brandFormat = config.brand_format;
  const titleFormat = config.title_format;
  const includeDescription = config.include_description ?? true;
  const maxImages = config.max_images ?? 5;
  const tagPropertyPrefix = config.tag_product_prefix || 'poshmark';
  const tagPropertyDelimiter = config.tag_product_delimiter || ':';

  const tagPayloadValues = extractPayloadValuesFromProductTags(tagPropertyPrefix, tagPropertyDelimiter, item.tags);

  const variables = {
    id: item.variant_id || item.product_id,
    images: getSortedUniqueImageUrls(item, maxImages),
    title: item.title,
    description: includeDescription && item.description ? getDescriptionText(item.description) : null,
    department: tagPayloadValues['gender'] || null,
    category: item.product_type,
    brand: item.vendor,
    price: item.compare_at_price ?? item.price_original ?? item.price,
    priceCurrencyCode: item.currency || 'USD',
    size: getProductOptionValue(item, 'size'),
    colors: [getProductOptionValue(item, 'color')].filter(v => v),
    mpn: item.sku,

    // Override all values with direct tag values
    ...tagPayloadValues
  };

  if (brandFormat) {
    variables.brand = buildString(brandFormat, { ...item, ...variables });
  }

  if (titleFormat) {
    variables.title = buildString(titleFormat, { ...item, ...variables });
  }

  // Per PMResell SDK 1.2.0 documentation, only the following keys are allowed:
  const allowedKeys = [
    'id', 'images', 'title', 'description', 'department', 'category', 'subcategory', 'brand',
    'brandMarketingName', 'price', 'priceCurrencyCode', 'size', 'colors', 'gtin', 'mpn'
  ];

  return {
    containerId: `poshmark-wrapper-${item.id}`,
    product: Object.fromEntries(
      Object.entries(variables)
        // Filter out null values and keys that are not in allowedKeys
        .filter(([key, value]) => allowedKeys.includes(key) && value != null)
    ),
  };
};

/**
 * Initializes the integration SDK if it is available.
 * @param {Object[]} resellItems - The list of available items to resell on Poshmark.
 * @param {Object} config - The configuration for the integration.
 * @returns {void}
 */
export const initPoshmarkSDK = (resellItems, config) => {
  if (!resellItems.length) {
    return;
  }

  const pmResell = new PMResell({
    key: config.key,
    partner: config.partner_id,
  });

  resellItems.forEach((item) => {
    const payload = generatePoshmarkSDKLineItemPayload(item, config);
    pmResell.addButton(payload);
  });

  pmResell.init();
};
